import punycode from "punycode";

export const apiDomain = "api.vzletka-cars.ru";
export const timezoneOffsetCurrent = 7 // в Красноярске (4), камчатское время (12), калининградское время (2)

export const domains = [
  {
    domain: "vzletka-cars.ru",
    slug: "vzletka-cars-ru",
    api: "api.vzletka-cars.ru",
    siteId: 475,
    isIndex: true
  },
  // {
  //   domain: "xn--124-5cdal8dqgc4ak.xn--p1ai",
  //   slug: "avtosalon124-rf",
  //   api: "api.xn--124-5cdal8dqgc4ak.xn--p1ai",
  //   siteId: 216,
  //   isIndex: false
  // },

]
export const devDomain = {
  domain: "vzletka-cars.ru",
  slug: "vzletka-cars-ru",
  api: "api.vzletka-cars.ru",
  siteId: 475,
  isIndex: false
}

export const mainDomain = domains.find(value => value.isIndex) || domains[0]
export function findDomain(host) {
  if (host.split("https://")[1]) {
    let currentHost = host.split("https://")[1];
   return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(currentHost)) || devDomain;
  } else {
    return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(host)) || devDomain;
  }
}
